import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState({
    key: 'vuex',
    storage: window.sessionStorage,
    paths: [
      "address",
      "issue",
      "branch",
      "registration",
      "billing_month",
      "use_date",
      "fee",
      "subsidy",
      "limit",
      "input_type",
    ] // ストレージに入れる情報
  })],
  state: {
    address: "",
    issue: "",
    branch: "",
    registration: "",
    billing_month: "",
    use_date: "",
    fee: "",
    subsidy: "900",
    limit: "",
    input_type: "",
    status: false,
  },
  getters: {
  },
  mutations: {
    setAddress(state, address){
      state.address = address
    },
    setIssue(state, issue){
      state.issue = issue
    },
    setBranch(state, branch){
      state.branch = branch
    },
    setRegistration(state, registration){
      state.registration = registration
    },
    setBilling_month(state, billing_month){
      state.billing_month = billing_month
    },
    setUse_date_string(state, use_date){
      state.use_date = use_date
    },
    setFee(state, fee){
      state.fee = fee
    },
    setSubsidy(state, subsidy){
      state.subsidy = subsidy
    },
    setLimit(state, limit){
      state.limit = limit
    },
    setInput_type(state, input_type){
      state.input_type = input_type
    },
    setStatus(state, status){
      state.status = status
    },
  },
  actions: {
  },
  modules: {
  }
})
