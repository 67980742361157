import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('@/components/pages/TheTop.vue')
  },
  {
    path: '/qr',
    name: 'qr',
    component: () => import('@/components/pages/TheQr.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/components/pages/TheForm.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('@/components/pages/TheConfirmation.vue')
  },
  {
    path: '/complete',
    name: 'complete',
    component: () => import('@/components/pages/TheComplete.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/components/pages/TheError.vue')
  },
]
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
})

export default router
